code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: -webkit-fill-available;
  max-height: 100vh;
  /* Mobile fix */
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

body {
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  overflow: hidden;
}

.root {
  display: flex;
  flex-grow: 1;
  height: 100%;
}
.app {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
